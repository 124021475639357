let mainMenuToggle: HTMLElement | null;
let currentMainMenuItem: HTMLElement | null;

function toggleMainMenu (this: HTMLElement, event: UIEvent): void {
    // Always close current main menu item first
    if (currentMainMenuItem) {
        setCollapsed(currentMainMenuItem);
    }
    const isExpanded = this.getAttribute('aria-expanded') || 'false';
    if (isExpanded !== 'true') {
        document.body.classList.add('main-menu-expanded');
        this.setAttribute('aria-expanded', 'true');
    } else {
        document.body.classList.remove('main-menu-expanded');
        this.setAttribute('aria-expanded', 'false');
    }

    event.preventDefault();
}

function setCollapsed (mainMenuItem: HTMLElement): void {
    if (mainMenuItem === currentMainMenuItem) {
        currentMainMenuItem = null;
    }
    mainMenuItem.classList.remove('expanded');
    if (mainMenuItem?.classList.contains('header-search')) {
        document.body.classList.remove('search-menu-expanded');
    } else {
        document.body.classList.remove('sub-menu-expanded');
    }
    const childNode = mainMenuItem.querySelector('a');
    if (childNode) {
        childNode.setAttribute('aria-expanded', 'false');
    }
}

function setExpanded (mainMenuItem: HTMLElement): void {
    // Always close current main menu item first
    if (currentMainMenuItem) {
        setCollapsed(currentMainMenuItem);
    }
    if (mainMenuItem?.classList.contains('header-search')) {
        // Close main menu if it is currently expanded
        if (document.body.classList.contains('main-menu-expanded')) {
            mainMenuToggle?.click();
        }
        document.body.classList.add('search-menu-expanded');
    } else {
        document.body.classList.add('sub-menu-expanded');
    }
    currentMainMenuItem = mainMenuItem;
    mainMenuItem.classList.add('expanded');
    const childNode = mainMenuItem.querySelector('a');
    if (childNode) {
        childNode.setAttribute('aria-expanded', 'true');
    }
}

function toggle (mainMenuItemToggle: HTMLElement): void {
    const mainMenuItem = (mainMenuItemToggle.parentElement as HTMLElement);
    if (mainMenuItem === currentMainMenuItem || mainMenuItemToggle.classList.contains('expanded')) {
        setCollapsed(mainMenuItem);
    } else {
        setExpanded(mainMenuItem);
    }
}

function hasMouseOrKeyboardFocusMovedOutsideMainMenuItem (event: MouseEvent | FocusEvent): boolean {
    // If we don't have related target info, return false
    if (event.relatedTarget === null) {
        return false;
    }
    // Ignore event if we're still within the same .main-item
    return (event.target as HTMLElement)?.closest('.main-item') !== (event.relatedTarget as HTMLElement)?.closest('.main-item');
}

function hasMouseOrKeyboardFocusMovedOutsideMainMenu (event: MouseEvent | FocusEvent): boolean {
    // If we don't have related target info, return true (mouse click outside area)
    if (event.relatedTarget === null) {
        return true;
    }
    // Ignore event if we're still within the same .main-menu
    return (event.target as HTMLElement)?.closest('.main-menu') !== (event.relatedTarget as HTMLElement)?.closest('.main-menu');
}

function moveFocusToToggle (event: KeyboardEvent | FocusEvent, toggle: HTMLElement | null): void {
    if (toggle && window.getComputedStyle(toggle).display !== 'none') {
        event.preventDefault();
        toggle.focus();
    }
}

function megaMenu (selector: string): void {
    mainMenuToggle = document.querySelector<HTMLElement>(selector + ' .menu-toggle');
    mainMenuToggle?.addEventListener('click', toggleMainMenu);

    const mobileQuery = window.matchMedia('(max-width: 1023px)'); // test the same ways as in CSS ($breakpointMedium)
    document.querySelector<HTMLElement>(selector)?.addEventListener('focusout', function (event): void {
        if (!mobileQuery.matches && hasMouseOrKeyboardFocusMovedOutsideMainMenu(event) && currentMainMenuItem) {
            setCollapsed(currentMainMenuItem);
        }
    });

    const searchToggle = document.querySelector<HTMLElement>('.search-toggle') || null;
    document.querySelector<HTMLElement>(selector + ' .solr-search')?.addEventListener('keydown', (event: KeyboardEvent): void => {
        if (event.key === 'Tab' && !event.shiftKey && mobileQuery.matches) {
            moveFocusToToggle(event, searchToggle);
        }
    });

    const elementsWithSubmenu = document.querySelectorAll<HTMLAnchorElement>(selector + ' .expandable');
    for (const elementWithSubmenu of elementsWithSubmenu) {
        // Events toggling the submenu (click / touch)
        const mainMenuLink = elementWithSubmenu.querySelector<HTMLAnchorElement>('a[data-sub-menu-toggle="true"]');
        if (mainMenuLink) {
            mainMenuLink.addEventListener('click', function (this: HTMLElement, event): void {
                toggle(this);
                event.preventDefault();
            });
        }
        const searchMenuLink = elementWithSubmenu.querySelector<HTMLAnchorElement>('.search-toggle');
        if (searchMenuLink) {
            searchMenuLink.addEventListener('click', function (this: HTMLElement, event): void {
                toggle(this);
                event.preventDefault();
            });
        }

        elementWithSubmenu.addEventListener('focusout', function (this: HTMLElement, event): void {
            if (!mobileQuery.matches && hasMouseOrKeyboardFocusMovedOutsideMainMenuItem(event)) {
                setCollapsed(this);
            }
        });
    }

    document.body.addEventListener('keydown', function (event) {
        if (currentMainMenuItem && (event.key === 'Escape' || event.key === 'Esc')) {
            setCollapsed(currentMainMenuItem);
        }
    });

    document.body.addEventListener('click', function (event) {
        const menu = document.querySelector('.header-menu-wrapper');
        if (menu) {
            if (!menu.contains(event.target as Node) && currentMainMenuItem) {
                setCollapsed(currentMainMenuItem);
            }
        }
    });

    const menuBackButton = document.querySelector<HTMLElement>(' .menu-back button');
    if (menuBackButton) {
        menuBackButton.addEventListener('click', function (): void {
            if (currentMainMenuItem) {
                const expandedElement = document.querySelector('.main-item.expanded');
                setCollapsed(currentMainMenuItem);
                if (expandedElement) {
                    const previousMenuItem = expandedElement.previousElementSibling;
                    if (previousMenuItem) {
                        previousMenuItem.querySelector('a')?.focus();
                    } else {
                        document.querySelector<HTMLElement>('.toggle')?.focus();
                    }
                }
            }
        });
    }

    // close main menu when clicking the main logo
    const mainLogo = document.querySelector<HTMLElement>('.header-left-logo svg');
    if (mainLogo && mainMenuToggle) {
        mainLogo.addEventListener('click', function (): void {
            if (mainMenuToggle) {
                // Always close current main menu item first
                if (currentMainMenuItem) {
                    setCollapsed(currentMainMenuItem);
                }
                const isExpanded = mainMenuToggle.getAttribute('aria-expanded') || 'false';
                if (isExpanded === 'true') {
                    document.body.classList.remove('main-menu-expanded');
                    mainMenuToggle.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }
}

export default megaMenu;
