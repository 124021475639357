function initForms (): void {
    for (const uploadButton of document.querySelectorAll<HTMLButtonElement>('.upload-button')) {
        const inputFieldId = uploadButton.getAttribute('data-for') || '';
        const inputField = document.getElementById(inputFieldId);
        if (inputField) {
            const outputField = document.getElementById(inputFieldId + '-filename');
            if (outputField) {
                inputField.addEventListener('change', (e) => {
                    outputField.innerText = (e.target as HTMLInputElement).value.split('\\').pop() || '';
                });
            }
            uploadButton.addEventListener('click', () => inputField.click());
        }
    }

    for (const form of document.querySelectorAll<HTMLFormElement>('form')) {
        form.addEventListener('submit', () => {
            const submitButton = form.querySelector<HTMLButtonElement>('.form-navigation button[type=submit]');
            if (submitButton) {
                submitButton.classList.add('form-submitted', 'disabled');
            }
        });
    }
}

export default initForms;
